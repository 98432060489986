<template>
  <v-dialog v-model="show" persistent scrollable max-width="800px">
    <v-card outlined>
      <v-card-title>
        {{ $t("Dicționar") }}
        <v-spacer />

        <v-icon color="red" class="mt-n2 mr-n2" large @click="show = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-form ref="dataForm">
          <v-row dense>
            <v-col cols="4">
              <v-text-field v-model="title" autofocus :label="$t('Nume')" :rules="inputRequired" />
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="dictionary" :label="$t('Valoare')" :rules="inputRequired" />
            </v-col>
            <v-col cols="4">
              <v-checkbox v-model="active" :label="$t('Activ')" />
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="appTypes"
                dense
                multiple
                :label="$t('Tip aplicație')"
                item-text="name"
                item-value="value"
                :items="appTypesValues"
              />
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="companies"
                dense
                multiple
                :label="$t('Companie')"
                item-text="name"
                item-value="_id"
                :disabled="appType != 'system'"
                :items="companiesValues"
              />
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="users"
                dense
                multiple
                :label="$t('Utilizator')"
                item-text="username"
                item-value="_id"
                :items="usersValues"
              />
            </v-col>
          </v-row>
          <v-row
            v-for="(itm, index) in content"
            :key="index"
            dense
            @mouseenter="itm.showBtn = true"
            @mouseleave="itm.showBtn = false"
          >
            <v-col cols="6">
              <v-text-field
                v-model="itm.name"
                :label="$t('Etichetă')"
                :rules="inputRequired"
                @mouseenter="itm.showBtn = true"
                @mouseleave="itm.showBtn = false"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="itm.value"
                :label="$t('Valoare')"
                :rules="inputRequired"
                @mouseenter="itm.showBtn = true"
                @mouseleave="itm.showBtn = false"
              >
                <template v-slot:append-outer>
                  <v-icon
                    v-show="itm.showBtn && index >= 0"
                    @mouseenter="itm.showBtn = true"
                    @mouseleave="itm.showBtn = false"
                    @click="addItem(index)"
                  >
                    mdi-plus
                  </v-icon>
                  <v-icon
                    v-show="itm.showBtn && index >= 0"
                    @mouseenter="itm.showBtn = true"
                    @mouseleave="itm.showBtn = false"
                    @click="deleteItem(index)"
                  >
                    mdi-minus
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text outlined @click="show = false">
          {{ $t("Anulează") }}
        </v-btn>
        <v-btn color="primary" @click="saveData">
          {{ $t("Salvează") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- <component :is="MyComp" ref="newcomp" /> -->
  </v-dialog>
</template>

<script>
import axios from "../../plugins/axios"
import { EventBus } from "../../EventBus"
// import Vue from "vue"
export default {
  components: {},
  data: () => ({
    MyComp: "v-btn",
    ind: 0,
    companiesValues: [],
    usersValues: [],
    appTypesValues: [],
    companies: [],
    users: [],
    appTypes: [],
    dictionary: "",
    recordId: "",
    record: {},
    etag: "",
    show: false,
    showBtn: false,
    active: true,
    title: "",
    content: [{ name: "", value: "", showBtn: false }],
    components: [
      "v-alert",
      "v-app-bar-nav-icon",
      "v-app",
      "v-app-bar",
      "v-avatar",
      "v-badge",
      "v-banner",
      "v-bottom-navigation",
      "v-bottom-sheet",
      "v-breadcrumbs-item",
      "v-breadcrumbs",
      "v-btn-toggle",
      "v-btn",
      "v-card-actions",
      "v-card-subtitle",
      "v-card-text",
      "v-card-title",
      "v-card",
      "v-carousel-item",
      "v-carousel",
      "v-chip",
      "v-chip-group",
      "v-container",
      "v-data-iterator",
      "v-data",
      "v-date-picker",
      "v-dialog",
      "v-edit-dialog",
      "v-expansion-panel",
      "v-expansion-panel-content",
      "v-expansion-panel-header",
      "v-flex",
      "v-footer",
      "v-form",
      "v-hover",
      "v-icon",
      "v-img",
      "v-item",
      "v-layout",
      "v-list-group",
      "v-list-item-action-text",
      "v-list-item-action",
      "v-list-item-avatar",
      "v-list-item-content",
      "v-list-item-group",
      "v-list-item-icon",
      "v-list-item-subtitle",
      "v-list-item-title",
      "v-list",
      "v-menu",
      "v-navigation-drawer",
      "v-overlay",
      "v-parallax",
      "v-rating",
      "v-responsive",
      "v-sheet",
      "v-simple-table",
      "v-slide-group",
      "v-slide-item",
      "v-snackbar",
      "v-speed-dial",
      "v-stepper-header",
      "v-stepper-items",
      "v-stepper-step",
      "v-stepper",
      "v-stepper-content",
      "v-subheader",
      "v-tab-item",
      "v-tab",
      "v-tabs-items",
      "v-tabs",
      "v-timeline",
      "v-timeline-item",
      "v-time-picker",
      "v-toolbar-items",
      "v-toolbar-title",
      "v-toolbar",
      "v-tooltip",
      "v-window-item",
      "v-window"
    ]
  }),
  computed: {
    inputRequired() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userId() {
      return this.$store.getters.accountId
    },
    appType() {
      return this.$store.getters.appType
    },
    userappTypes() {
      return this.$store.getters.appTypes
    }
  },
  mounted() {
    let compurl = "app_company"
    let userurl = "app_user"
    if (this.appType !== "system") {
      compurl = 'app_company?where={"_id":"' + this.companyId + '"}'
      userurl = 'app_user?where={"company":"' + this.companyId + '"}'
      this.companies = [this.companyId]
    }
    axios.get('app_system_dictionary?where={"value":"app_type"}').then(response => {
      if (this.appType !== "system") {
        this.appTypesValues = response.data._items[0].content.filter(itm => this.userappTypes.includes(itm.value))
      } else {
        this.appTypesValues = response.data._items[0].content
      }
    })
    axios.get(compurl).then(response => {
      this.companiesValues = response.data._items
    })
    axios.get(userurl).then(response => {
      this.usersValues = response.data._items
    })
  },
  methods: {
    addItem(ind) {
      this.content.splice(ind + 1, 0, { name: "", value: "", showBtn: false })
    },
    deleteItem(ind) {
      if (this.content.length > 1) {
        this.content.splice(ind, 1)
      } else {
        this.content.splice(ind, 1, { name: "", value: "", showBtn: false })
      }
    },
    addData() {
      this.show = true
      this.recordId = 0
      this.title = ""
      this.dictionary = ""
      this.active = true
      this.content = [{ name: "", value: "", showBtn: false }]
      this.$nextTick(() => this.$refs.dataForm.resetValidation())
    },
    editData(e) {
      this.show = true
      this.recordId = e._id
      this.etag = e._etag
      this.title = e.name
      this.dictionary = e.value
      this.active = e.active
      this.$log("etag ", e)
      axios.get("app_dictionary/" + this.recordId).then(response => {
        this.$log("app_template", response)
        if (response.data.owner) {
          this.companies = response.data.owner.company
          this.users = response.data.owner.user
          this.appTypes = response.data.owner.app_type
        } else {
          this.companies = []
          this.users = []
          this.appTypes = []
        }
        this.etag = response.data._etag
        this.content = response.data.content
        this.content.map(itm => (itm.showBtn = false))
      })
    },
    changeComponent(comp) {
      this.MyComp = comp
      this.$log("comp ", comp)
      this.$nextTick(() => {
        const con = []
        for (const itm in this.$refs.newcomp.$props) {
          con.push({ name: itm, value: typeof this.$refs.newcomp.$props[itm] })
          // this.$log("prop " + itm + " -  " + typeof this.$refs.newcomp.$props[itm])
        }
        axios
          .post("app_system_dictionary/", {
            name: "Vuetify Components " + comp,
            value: "vuetify_components_" + comp,
            content: con
          })
          .then(response => {
            this.$log("saveData", response)
          })
          .catch(error => {
            this.$log("error ", error)
            // this.$log("error ", error._issues.content.name)
            EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
          })
        this.$log("----------")
        this.ind++
        if (this.ind < this.components.length) {
          this.changeComponent(this.components[this.ind])
        }
      })
    },

    saveData() {
      // this.changeComponent(this.components[this.ind])
      // let con = []
      // this.components.map(itm => con.push({ name: itm, value: itm }))
      // axios
      //   .post("app_system_dictionary/", {
      //     name: "Vuetify Components",
      //     value: "vuetify_components",
      //     content: con
      //   })
      //   .then(response => {
      //     this.$log("saveData", response)
      //   })
      //   .catch(error => {
      //     this.$log("error ", error)
      //     //this.$log("error ", error._issues.content.name)
      //     EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
      //   })

      if (this.$refs.dataForm.validate()) {
        if (this.recordId === 0) {
          this.$log("file1 ", this.file)
          const con = [...this.content]
          con.map(itm => (itm.showBtn = undefined))
          axios
            .post("app_dictionary/", {
              name: this.title,
              value: this.dictionary,
              active: this.active,
              content: con,
              owner: {
                user: this.users,
                company: this.companies,
                app_type: this.appTypes
              }
            })
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 201) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        } else {
          const con = [...this.content]
          con.map(itm => (itm.showBtn = undefined))
          axios
            .put(
              "app_dictionary/" + this.recordId,
              {
                name: this.title,
                value: this.dictionary,
                active: this.active,
                content: con,
                owner: {
                  user: this.users,
                  company: this.companies,
                  app_type: this.appTypes
                }
              },
              {
                headers: {
                  "If-Match": this.etag
                }
              }
            )
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 200) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        }
      }
    }
  }
}
</script>
